/* CSS Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

html,
body {
  height: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h2 {
  font-weight: normal;
}

a {
  text-decoration: none;
}

hr {
  margin: 2em 0 2em 0;
  color: black;
  width: 80%;
}

.content {
  display: grid;
  grid-template-columns: 20vw 70vw;
  grid-template-areas: "nav main";
  background-color: var(--bg-colour);
}

.navbar {
  grid-area: nav;
}

.navbarFixedContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
}

.navbarContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  width: 100%;
}

.navItem {
  padding: 0.5em 0 0.5em 0.5em;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  margin: 0.5em 0 0.5em 0;
  background: var(--text-colour);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navItem:hover {
  cursor: pointer;
  animation: navItemHover 0.1s ease-in-out 0s 1 normal both;
  background: var(--primary-colour);
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: #ffffff;
}

.card-container {
  margin: 1em 3.8em 0 0;
}

.dropcard-container {
  margin: 1em 3.8em 0 0;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.container {
  margin-top: 5rem;
  margin-right: 10rem;
  display: grid;
  width: 90%;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto 100px;
  grid-template-areas:
    "title ."
    "subtitle pic"
    "subtitle pic"
    "subtitle pic"
    "signature pic"
    "social-media pic"
  ;
}

.profile-picture {
  border-radius: 100px;
  border: 5px;
  width: 320px;
  height: auto;
  object-fit: cover;
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.5);
}

.profile-picture-container {
  grid-area: pic;
}

.landing-title-container {
  grid-area: title;
  width: fit-content;
  justify-content: left;
  align-items: left;
}

.landing-title {
  width: fit-content;
  justify-content: left;
}

.landing-subtitle-container {
  grid-area: subtitle;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 10%;
}

.landing-subtitle {
  text-align: justify;
  text-wrap: wrap;
}

.line-1 {
  top: 50%;
  margin: 0 0 0 0;
  border-right: 2px solid var(--primary-colour);
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
}

.anim-typewriter {
  animation: typewriter 1s steps(14) 1s 1 normal both,
    blinkTextCursor 700ms steps(14) infinite normal;
}

.landing-signature-container {
  grid-area: signature;
}

.signature {
  margin: 1em 0 0 0;
}

.social-media-icon {
  margin: 0 1em 0 0em;
}

.social-media-list {
  grid-area: social-media;
  display: flex;
  flex-direction: row;
}

.section {
  margin: 4em 0 0 0;
}

.section-title {
  display: block;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  background: var(--section-colour);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title::before {
  content: ">";
  color: #ffffff;
  position: relative;
  bottom: 2px;
  right: 8px;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: var(--primary-colour);
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.section-divider {
  display: flex;
  align-items: right;
  border: none;
  margin: 0 0 0 1.5em;
  transform: translateY(4px);
  border-top: 2.2px dashed rgba(70, 70, 70, 0.61);
  color: #fff;
  background-color: #fff;
  height: 1px;
}

@media screen and (max-width: 1000px) {
  .main {
    padding-left: 20px;
    padding-right: 10px;
    overflow: hidden;
  }

  .content {
    display: grid;
    width: 100vw;
    grid-template-columns: auto;
    grid-template-areas: "main";
    background-color: var(--bg-colour);
  }

  .container {
    margin-top: 2.5rem;
    display: grid;
    grid-template-columns: 90vw;
    grid-template-rows: auto;
    grid-template-areas:
      "title"
      "pic"
      "subtitle"
      "subtitle"
      "subtitle"
      "signature"
      "social-media"
    ;
  }

  .landing-title-container {
    margin-left: auto;
    margin-right: auto;
  }

  .profile-picture-container {
    margin: 1em auto;
  }

  .landing-subtitle-container {
    padding-right: 0;
  }

  .landing-subtitle {
    margin: 10px 0;
  }

  .section {
    width: 90vw;
  }

  .card-container {
    margin: 1em 0 0 0;
  }

  .dropcard-container {
    margin: 1em 0 0 0;
  }

}

@keyframes typewriter {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: var(--primary-colour);
  }

  to {
    border-right-color: transparent;
  }
}

@keyframes navItemHover {
  from {
    background: var(--text-colour);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  to {
    background: var(--primary-colour);
    -webkit-background-clip: border-box;
    -webkit-text-fill-color: #ffffff;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  }
}