.dropcard {
  width: 100%;
  border-radius: 20px;
  background: #fcfbfb;
  position: relative;
  padding: 0em 1.8rem 1em 1.8rem;
  border: 2px solid #e7e7e7fa;
  transition: 0.2s ease-out;
  overflow: visible;
}

.dropcard-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1em 0 0 0;
}

.dropdown {
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
  justify-content: flex-end;
}

.dropcard.active .dropdown-arrow {
  transform: rotate(90deg);
  transition: all 0.5s ease-in-out;
}

.dropdown-arrow {
  transition: all 0.5s ease-in-out;
}

.dropdown:hover {
  cursor: pointer;
}

.tags {
  width: max-content;
  display: flex;
  flex-direction: row;
}

.dropcard-details {
  display: grid;
  color: black;
  height: 100%;
  gap: 3em;
  max-height: 0px;
  transition: var(--dropcard-transition);
  overflow: hidden;
}

.dropcard-details.active {
  max-height: var(--dropcard-height);
  transition: var(--dropcard-transition);
}

.dropcard-details .dropdown-body {
  color: var(--card-text-colour);
  font-size: large;
  text-align: justify;
}

.content-container {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  flex-direction: column;
  justify-content: center;
  margin: 1em 0 0 0;
}

.header-text {
  display: grid;
  width: fit-content;
  grid-row: 1;
  border-right: 1px solid rgb(189, 189, 189);
  padding: 0 1.7em 0 0;
  margin-right: 1em;
}

.header-text-resume {
  display: grid;
  width: fit-content;
  grid-row: 1;
  padding: 0 1.7em 0 0;
}

/*Text*/
.dropdown-title {
  font-size: 1.5em;
  margin: 0, 0, 0, 0;
}

.dropcard.tag-selected .dropdown-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0, 0, 0, 0;
}

.dropdown-subtitle {
  font-size: 1em;
  font-weight: normal;
  font-style: italic;
  margin: 0, 0, 0, 0;
}

/*Hover*/
.dropcard:hover {
  border-color: var(--primary-colour);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.dropcard:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}

.url-container {
  display: grid;
  grid-column: 4;
  grid-row: 1;
  justify-content: right;
  margin: 0 1em 1em 0;
}

.url-button {
  margin: 0.5em 0 0 0;
  padding: 0.2em 0.5em 0.2em 0.5em;
  text-decoration: none;
  border-radius: 10px;
  font-size: 26px;
  font-weight: 600;
  max-height: 0px;
  background: var(--text-colour);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: var(--dropcard-transition);
  text-wrap: nowrap;
}

.project-url {
  grid-row: 5;
}

.code-url {
  grid-row: 4;
}

.url-button:hover {
  cursor: pointer;
  animation: urlButtonHover 0.1s ease-in-out 0s 1 normal both;
  background: var(--primary-colour);
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-text-fill-color: #ffffff;
}

.url-button.active {
  max-height: var(--dropcard-height);
  transition: var(--dropcard-transition);
}

@media screen and (max-width: 1000px) {
  .dropcard-header {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .header-text {
    border-right: none;
  }

  .tags {
    align-items: start;
  }

  .dropcard-details {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .url-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .dropdown {
    margin-left: auto;
  }

  .dropcard-details.active {
    max-height: fit-content;
  }

  .url-button.active {
    max-height: fit-content;
  }
}

@keyframes urlButtonHover {
  from {
    background: var(--text-colour);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  to {
    background: var(--primary-colour);
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-text-fill-color: #ffffff;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
  }
}