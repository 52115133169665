.tag {
  margin: 0 0.5em 0 0.5em;
  border-radius: 10px;
  color: var(--primary-colour);
}

.missing-tag {
  background-color: var(--primary-colour);
  color: var(--bg-colour);
  padding: 0.1em 0.28em 0.1em 0.28em;
  border-radius: 100px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-0.1em);
}
