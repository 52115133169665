:root {
  --primary-colour: #3f8efc;
  --secondary-colour: #00ff00;
  --bg-colour: #ffffff;
  --text-colour: -webkit-linear-gradient(225deg,
      rgb(123, 167, 255) 35%,
      var(--primary-colour) 100%);
  --dropcard-height: 300px;

  --dropcard-transition: max-height 0.5s ease-in-out;
  --card-text-colour: rgb(68, 68, 68);
  --section-colour: -webkit-linear-gradient(112.1deg,
      rgb(136, 135, 135) 11.4%,
      black 70.2%);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--bg-colour);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}