.card {
  width: 100%;
  border-radius: 20px;
  background: #fcfbfb;
  position: relative;
  padding: 1em 1.8rem 1rem 1.8rem;
  border: 2px solid #e7e7e7fa;
  transition: 0.5s ease-out;
  overflow: visible;
}

.card-details {
  height: 100%;
  display: grid;
  place-content: left;
}

.card-body {
  color: var(--card-text-colour);
  font-size: large;
}

/*Text*/
.card-title {
  font-size: 1.5em;
  font-weight: bold;
}

/*Hover*/
.card:hover {
  border-color: var(--primary-colour);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}

.card:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}